<div class="portal-list__container">
  @for (credential of credentials; track credential.id) {
    <app-portal-credential
      [credential]="credential"
      [config]="getConfig(credential)"
      [selected]="isSelected(credential)"
      [disabled]="disabled || forceDisabled"
      [selectable]="allowSelect"
      [removable]="allowRemove"
      [deselectable]="!exists(credential)"
      (toggle)="onToggle($event)"
      (remove)="onRemovePortal($event)"
    >
    </app-portal-credential>
  }
</div>
@if (allowAdd) {
  <div class="portal-list__add-button">
    <app-button
      [iconLeft]="'add'"
      [disabled]="disabled"
      [type]="'light-bordered'"
      (clickEvent)="onAddPortal()"
      >{{ 'ADD_PORTAL_A' | translate }}
    </app-button>
  </div>
}
