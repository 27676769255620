import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  ChangeDetectionStrategy,
  SimpleChanges
} from '@angular/core';

import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { filter } from 'rxjs/operators';

import {
  PortalCredential,
  PortalConfig,
  PortalCredentialProperties
} from '@ui/shared/models';

import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from 'libs/components';
import {
  isImmoscout,
  isHomepageModule,
  isResident,
  isWillhaben,
  isImmobilie1
} from '../../utils';
import { PortalCredentialDetailsComponent } from '../portal-credential-details/portal-credential-details.component';
import { PortalCredentialDetailsWithProviderComponent } from '../portal-credential-details-with-provider/portal-credential-details-with-provider.component';
import { ResidentCredentialsDetailsComponent } from '../resident-credential-details/resident-credential-details.component';
import { HomepageModuleCredentialDetailsComponent } from '../homepage-module-credential-details/homepage-module-credential-details.component';
import { ImmoscoutCredentialsDetailsComponent } from '../immoscout-credential-details/immoscout-credential-details.component';

@Component({
  selector: 'app-available-portal',
  templateUrl: './available-portal.component.html',
  styleUrls: ['./available-portal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    ImmoscoutCredentialsDetailsComponent,
    HomepageModuleCredentialDetailsComponent,
    ResidentCredentialsDetailsComponent,
    PortalCredentialDetailsWithProviderComponent,
    PortalCredentialDetailsComponent,
    TranslateModule
  ]
})
export class AvailablePortalComponent implements OnInit, OnChanges {
  @Input() portalConfig: PortalConfig;
  @Input() selected = false;

  @Output() toggle = new EventEmitter<PortalCredential>();
  @Output() update = new EventEmitter<PortalCredential>();
  @Output() getIs24URL = new EventEmitter();

  public credentialFormType = {
    IMMOSCOUT: 'IMMOSCOUT',
    HOMEPAGE_MODULE: 'HOMEPAGE_MODULE',
    RESIDENT_APP: 'RESIDENT_APP',
    WILLHABEN: 'WILLHABEN',
    DEFAULT: 'DEFAULT',
    IMMOBILIE1: 'IMMOBILIE1'
  };

  public credential: PortalCredential;
  public credentialProperties: PortalCredentialProperties;

  public checkControl = new FormControl(this.selected);

  public credentialDetailsControl = new FormControl({
    value: {}
  });

  public get isImmoscout() {
    return isImmoscout(this.portalConfig.portalId);
  }

  public get isResident() {
    return isResident(this.portalConfig.portalId);
  }

  public get isHomepageModule() {
    return isHomepageModule(this.portalConfig.portalId);
  }

  public get formType() {
    if (this.isImmoscout) {
      return this.credentialFormType.IMMOSCOUT;
    }

    if (this.isHomepageModule) {
      return this.credentialFormType.HOMEPAGE_MODULE;
    }

    if (this.isResident) {
      return this.credentialFormType.RESIDENT_APP;
    }

    if (isWillhaben(this.portalConfig.portalId)) {
      return this.credentialFormType.WILLHABEN;
    }

    if (isImmobilie1(this.portalConfig.portalId)) {
      return this.credentialFormType.IMMOBILIE1;
    }

    return this.credentialFormType.DEFAULT;
  }

  public ngOnInit() {
    this.credential = {
      portal: this.portalConfig.portalId
    } as PortalCredential;

    this.checkControl.patchValue(this.selected);

    this.checkControl.valueChanges
      .pipe(
        /**
         * We want to emit "toggle" event when:
         * 1. We just selected given portal OR
         * 2. We deselect portal which has been selected already
         */
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        filter(value => value || (!value && this.selected))
      )
      .subscribe(() => this.toggle.emit(this.credential));

    this.credentialDetailsControl.valueChanges
      .pipe(filter(() => this.selected))
      .subscribe(credential => {
        this.credential = {
          ...this.credential,
          ...credential
        };
        this.update.emit(this.credential);
      });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.selected && !changes.selected.currentValue) {
      this.checkControl.patchValue(false);
    }
  }

  public onHeaderClick() {
    this.checkControl.patchValue(!this.checkControl.value);
  }

  public onGetIs24URL() {
    this.getIs24URL.emit();
  }
}
