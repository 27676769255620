import { Injectable, inject } from '@angular/core';

import { Store } from '@ngrx/store';

import { tap, map, withLatestFrom } from 'rxjs/operators';

import * as fromBaseState from 'libs/infrastructure/base-state';
import * as fromAppState from 'admin/+state';
import { AdminFindUserTypes } from 'admin/models';

@Injectable()
export class LandlordGuard {
  private store = inject<Store<fromBaseState.AppState>>(Store);

  canActivate() {
    return this.store.select(fromAppState.getSearchDataLoaded).pipe(
      withLatestFrom(this.store.select(fromBaseState.getRouterParams)),
      tap(([loaded, route]) => {
        if (!loaded) {
          this.store.dispatch(
            new fromAppState.FetchSearchResults({
              userType: AdminFindUserTypes.LANDLORD,
              fieldValue: route.userId,
              fieldType: 'id'
            })
          );
        }
      }),
      map(() => true)
    );
  }
}
