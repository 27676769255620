import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ChangeDetectionStrategy
} from '@angular/core';

import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PortalCredential, PortalConfig, PortalType } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgClass } from '@angular/common';
import { ComponentsModule } from 'libs/components';

@Component({
  selector: 'app-portal-credential',
  templateUrl: './portal-credential.component.html',
  styleUrls: ['./portal-credential.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ComponentsModule,
    NgClass,
    FormsModule,
    ReactiveFormsModule,
    NgbTooltip,
    TranslateModule
  ]
})
export class PortalCredentialComponent implements OnInit {
  @Input() credential: PortalCredential;
  @Input() config: PortalConfig;
  @Input() selected = false;

  // behaviour control inputs
  @Input() disabled = false;
  @Input() selectable = true;
  @Input() removable = false;
  // temporary
  @Input() deselectable = true;

  @Output() toggle = new EventEmitter<PortalCredential>();
  @Output() remove = new EventEmitter<PortalCredential>();

  public checkControl = new FormControl({
    value: this.selected,
    disabled: this.isDisabled
  });

  public get isImmoscout() {
    return this.credential?.portal === PortalType.IMMOBILIENSCOUT24_DE;
  }

  public ngOnInit() {
    this.checkControl.patchValue(this.selected);

    this.checkControl.valueChanges.subscribe(() =>
      this.toggle.emit(this.credential)
    );
  }

  public onRemove() {
    this.remove.emit(this.credential);
  }

  public onBoxClick() {
    if (this.isDisabled) return;
    this.checkControl.patchValue(!this.checkControl.value);
  }

  public get isDisabled() {
    return (
      this.disabled || !this.selectable || (this.selected && !this.deselectable)
    );
  }
}
